<template>
  <div style="text-align: center">
    <h2>{{ objName }}健康状况</h2>
    <h3>（{{ year }}年度）</h3>
    <table id="table" cellspacing="0" class="tabel-container" width="98%" style="text-align: center"></table>
  </div>
</template>
<script>
  import { Project } from '@/api/pro/index.js'
  import { setToken } from '@/utils/auth'
  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {
        show: false,
        schemeId: this.$route.params.id,
        objName: decodeURIComponent(this.$route.params.name),
        year: this.$route.params.year,
        data: null
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.loadData()
    },
    methods: {
      /**
       * @description 初始化数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadData() {
        Project.getQrcodeInfo(this.schemeId).then((res) => {
          console.log('<==  ==>', res.data.data)
          this.data = res.data.data
          this.bindRiverHealthInfo()
        })
      },

      GetChild(arr) {
        let varT = ''
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].finalNodeSize == 0) {
            if (this.strHtml == '') {
              if (arr[i].scoreVal) {
                varT = arr[i]
                if (varT.scoreDesc) {
                  this.strHtml +=
                    '<td>' +
                    arr[i].name +
                    // "(" +
                    // arr[i].code +
                    // ")" +
                    '</td>' +
                    '<td>' +
                    varT.scoreVal.toFixed(1) +
                    '</td><td>' +
                    varT.scoreDesc +
                    '</td></tr>'
                } else {
                  this.strHtml +=
                    '<td>' +
                    arr[i].name +
                    // "(" +
                    // arr[i].code +
                    // ")" +
                    '</td>' +
                    '<td>' +
                    varT.scoreVal.toFixed(1) +
                    '</td><td>' +
                    '-' +
                    '</td></tr>'
                }
                this.strHtmlTotal += this.strHtml
                this.strHtml = ''
              } else {
                this.strHtml +=
                  '<tr><td>' +
                  arr[i].name +
                  // "(" +
                  // "-" +
                  // ")" +
                  '</td>' +
                  '<td>' +
                  '-' +
                  '</td><td>' +
                  '-' +
                  '</td></tr>'
                this.strHtmlTotal += this.strHtml
                this.strHtml = ''
              }
            } else {
              if (arr[i].scoreVal) {
                varT = arr[i]
                if (varT.scoreDesc) {
                  this.strHtml +=
                    '<td>' +
                    arr[i].name +
                    // "(" +
                    // arr[i].code +
                    // ")" +
                    '</td>' +
                    '<td>' +
                    varT.scoreVal.toFixed(1) +
                    '</td><td>' +
                    varT.scoreDesc +
                    '</td></tr>'
                } else {
                  this.strHtml +=
                    '<td>' +
                    arr[i].name +
                    // "(" +
                    // arr[i].code +
                    // ")" +
                    '</td>' +
                    '<td>' +
                    varT.scoreVal.toFixed(1) +
                    '</td><td>' +
                    '-' +
                    '</td></tr>'
                }

                this.strHtmlTotal += this.strHtml
                this.strHtml = ''
              } else {
                this.strHtml +=
                  '<td>' +
                  arr[i].name +
                  '(' +
                  arr[i].code +
                  ')' +
                  '</td>' +
                  '<td>' +
                  '-' +
                  '</td><td>' +
                  '-' +
                  '</td></tr>'
                this.strHtmlTotal += this.strHtml
                this.strHtml = ''
              }
            }
          } else if (arr[i].finalNodeSize == 1) {
            if (this.strHtml == '') {
              this.strHtml += '<tr><td>' + arr[i].name + '(' + arr[i].scoreVal.toFixed(1) + ')' + '</td>'
            } else {
              this.strHtml += '<td>' + arr[i].name + '(' + arr[i].scoreVal.toFixed(1) + ')' + '</td>'
            }
            this.GetChild(arr[i].children)
          } else {
            if (this.strHtml == '') {
              this.strHtml +=
                '<tr><td rowspan="' +
                arr[i].finalNodeSize +
                '">' +
                arr[i].name +
                '(' +
                arr[i].scoreVal.toFixed(1) +
                ')' +
                '</td>'
            } else {
              this.strHtml +=
                '<td rowspan="' +
                arr[i].finalNodeSize +
                '">' +
                arr[i].name +
                '(' +
                arr[i].scoreVal.toFixed(1) +
                ')' +
                '</td>'
            }
            this.GetChild(arr[i].children)
          }
        }
      },
      bindRiverHealthInfo() {
        const _this = this
        var result = this.data

        this.strHtml = ''
        this.strHtmlTotal = `<thead>
        <tr>
          <td>类别层</td>
          <td>要素层</td>
          <td>评估指标</td>
          <td>指标值</td>
          <td>评估结果</td>
        </tr>
      </thead>`
        if (result.finalNodeSize == 0) {
          this.strHtml += '<tr><td>' + result.name + '(' + result.code + ')' + '</td></tr>'
        } else {
          this.strHtml += '<tr>'
          this.GetChild(result.children)
        }
        this.getLayerDepth(result)
        let colspanNum = this.level - 1
        this.strHtmlTotal +=
          '<tr><td colspan="' +
          colspanNum +
          '">' +
          '综合得分' +
          '</td><td>' +
          this.data.scoreVal.toFixed(1) +
          '</td><td>' +
          this.data.scoreDesc +
          '</td></tr>'
        console.log('<== this.data ==>', this.data)
        document.getElementById('table').innerHTML = this.strHtmlTotal
      },
      getLayerDepth(result) {
        if (result.children) {
          this.getLayerDepth(result.children[0])
        } else {
          this.level = result.level
        }
      }
    }
  }
</script>
<style lang="less" scope>
  .tabel-container {
    thead {
      height: 48px;
      background: #eee;
      color: rgba(30, 34, 45, 1);
    }
    td {
      border: 1px solid #eee;
    }
  }
</style>
